import React, { useState,useEffect } from 'react';
import QRCode from 'qrcode.react';
import Sidebar from './Sidebar'; 
import Header from '../components/Header';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getDatabase, ref, onValue ,update} from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { app } from '../firebase'; 
import { useNavigate } from 'react-router-dom';
import Download from '@mui/icons-material/Download'
import Timer from '@mui/icons-material/Timer'
import PlayArrow from '@mui/icons-material/PlayArrow'
import Link from '@mui/icons-material/Link'
import Edit from '@mui/icons-material/Edit'
import axios, { AxiosError } from 'axios';
import UpdatingAnimation from '../components/UpdatingAnimation';
import SuccessAnimation from '../components/SuccessAnimation';
import instructionManual from '../images/confcap-instructions.jpg';
import playButton from '../images/playIcon.svg';
interface OverviewProps {
  name: string;
  imageUrl: string;
  qrCodeData: string;
}

const Overview: React.FC<OverviewProps> = ({ name: initialName, imageUrl: initialImageUrl, qrCodeData }) => {
  const randomStreamName = getRandomStreamName();
  const auth = getAuth(app);
  const [user, loading, error] = useAuthState(auth);
  const [streamData, setStreamData] = useState({
    uid:  user?.uid,
    streamName: initialName,
    imageUrl:initialImageUrl,
    streamColor: '' 
  });
  const [fetchedStreamData, setFetchedStreamData] = useState({
    uid: '',
    streamName: '',
    imageUrl: '',
    streamColor: '',
  });

  const [hasChanges, setHasChanges] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
      
    if (user) {
      console.log("A")
      const db = getDatabase();
      const userRef = ref(db, `users/${user.uid}`);
      onValue(userRef, (snapshot) => {
        console.log("B")
        const data = snapshot.val();
        if (data !== null) {
          console.log("C")
          setStreamData({
            uid: user.uid,
            streamName: data.name,
            imageUrl: data.imageLink,
            streamColor: data.color,
          });
          setFetchedStreamData({
            uid: user.uid,
            streamName: data.name,
            imageUrl: data.imageLink,
            streamColor: data.color,
          });
          CheckAndHandleDeeplinking(data.name)
        }
        else {
          // Data is null, handle this case appropriately
          console.error('No data available for this user.');
        }
      },(error) => {
        console.error('Firebase read failed: ', error);
      });
    }
  }, [user]);
  
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!user) {
    navigate('/login');
  }

  const handleEditDetails = () => {
    setIsEditing(true);
  };

  const handleSaveDetails = async () => {
    const hasChanges = 
    streamData.streamName !== fetchedStreamData.streamName || 
    streamData.imageUrl !== fetchedStreamData.imageUrl || 
    streamData.streamColor !== fetchedStreamData.streamColor;

  if (!hasChanges) {
    console.log('No changes detected. Update not required.');
    setIsEditing(false); // Exit editing mode without making an update
    return; // Exit the function early
  }
    setIsSaving(true);
    await DeleteStream(fetchedStreamData.streamName.toLowerCase());
    // await timeout(5000); //for 1 sec delay
    await createStreamWithStyle(streamData.streamName.toLowerCase(),streamData.imageUrl,streamData.streamColor);
    setIsEditing(false);
    setIsSaving(false);
    setShowSuccess(true);
    const startTime=Date.now();
    const endTime = Date.now(); // Record the end time of the API call
    let timeTaken = endTime - startTime;

    // Ensure user sees loading state for at least 3 seconds in total
    if (timeTaken < 1500) {
      await new Promise((resolve) => setTimeout(resolve, 1500 - timeTaken));
    }
    setShowSuccess(false);
    resetAfterSave(); 
    // Update Firebase database with new details if user is defined
    if (user) {
      const db = getDatabase();
      const userRef = ref(db, `users/${user.uid}`);
      update(userRef, {
        uid: auth.currentUser?.uid,
        name: streamData.streamName == "" ? randomStreamName : streamData.streamName,
        imageLink: streamData.imageUrl == "" ? "https://cdn-icons-png.flaticon.com/512/2395/2395089.png" : streamData.imageUrl,
        color: streamData.streamColor == "" ? "#888888" : streamData.streamColor
      })
      .then(() => {
        setFetchedStreamData({
          uid: auth.currentUser?.uid ?? "",
          streamName: streamData.streamName == "" ? randomStreamName : streamData.streamName,
          imageUrl: streamData.imageUrl == "" ? "https://cdn-icons-png.flaticon.com/512/2395/2395089.png" : streamData.imageUrl,
          streamColor: streamData.streamColor == "" ? "#888888" : streamData.streamColor
        });
        

        console.log('Details saved successfully.');
      })
      .catch((error) => {
        console.error('Error saving details: ', error);
      });
    } else {
      console.error('User is not defined.');
    }
  };
  
  

  const handleCancelEdit = () => {
    setIsEditing(false);
    setStreamData({
      uid: fetchedStreamData.uid,
      streamName:fetchedStreamData.streamName,
      imageUrl: fetchedStreamData.imageUrl,
      streamColor: fetchedStreamData.streamColor
    });
  };

  function CheckAndHandleDeeplinking(streamName:string){
    // Deep link logic
    // Eg: recipeapp://open-recipe?name=recipename, 
    // URL scheme recipeapp://, 
    // action is open-recipe, 
    // query parameter `name` to find recipename
    if (typeof window !== 'undefined') {
      var arg = new URLSearchParams(window.location.search);
      if (arg.get('activate') === "app" && streamName !== ""){
        window.location.href = 'confcap://open-stream?name=' + streamName + '&uid=' + user?.uid; // will result in error message if app not installed
      }
    }         
  }

  function getRandomStreamName(){
    return "stream" + Math.floor(Math.random() * 100000).toString();
  }

  const createStreamWithStyle = async (name:string,img:string,color:string) => {
    const apiUrl = 'https://api.deafassistant.com/stream/CreateStreamWithStyle';
    const data = {
      name: name == "" ? randomStreamName : name.toLowerCase(),
      logoUrl: img == "" ? "https://cdn-icons-png.flaticon.com/512/2395/2395089.png" : img,
      bannerColor: color == "" ? "#888888" : color
    };
  
     
  
    try {
      //axios.defaults.headers.post['Content-Type'] ='application/json; charset=utf-8';
      axios.defaults.headers.post['Accept'] ='application/json; charset=utf-8';
      const response = await axios.post(apiUrl, data);
      console.log(response);
      console.log("success");
  
      
  
      // Navigate after ensuring total wait time of at least 3 seconds
      
    } catch (error) {
      
      console.log();
      
      console.error('There was an error!', error);
      if (error instanceof AxiosError) {     
        alert('Error creating your stream. ' + error.response?.data + '. Refresh the page and try again with different values. If the error persists, email us at hi@deafassistant.com'); 
      }      
    }
  };
  const DeleteStream = async (name:string) => {
    const apiUrl = 'https://api.deafassistant.com/stream/DeleteStream';
    const data = {
      oldName: name.toLowerCase(),     
    };
  
    const startTime = Date.now(); // Record the start time of the operation
  
    try {
      //axios.defaults.headers.post['Content-Type'] ='application/json; charset=utf-8';
      axios.defaults.headers.post['Accept'] ='application/json; charset=utf-8';
      const response = await axios.post(apiUrl, data);
      console.log(response.data);
      console.log("success");
  
      const endTime = Date.now(); // Record the end time of the API call
      let timeTaken = endTime - startTime;
  
      // Ensure user sees loading state for at least 3 seconds in total
      if (timeTaken < 3000) {
        await new Promise((resolve) => setTimeout(resolve, 3000 - timeTaken));
      }
  
      // Navigate after ensuring total wait time of at least 3 seconds
      
    } catch (error) {
      console.error('There was an error!', error);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setStreamData(prevState => ({
      ...prevState,
      [name]: value
    }));
  
    // Check against the fetchedStreamData to see if there are any changes
    const currentData = { ...streamData, [name]: value };
    const changesDetected = 
      currentData.streamName !== fetchedStreamData.streamName ||
      currentData.imageUrl !== fetchedStreamData.imageUrl ||
      currentData.streamColor !== fetchedStreamData.streamColor;
  
    setHasChanges(changesDetected);
  };

  const resetAfterSave = () => {
    setHasChanges(false);
    setFetchedStreamData({
      uid: user?.uid ?? "",
      streamName: streamData.streamName,
      imageUrl: streamData.imageUrl,
      streamColor: streamData.streamColor,
    });
  };

  // Inside your component
const handleDownload = () => {
  const link = document.createElement('a');
    link.href = instructionManual;
    link.download = 'InstructionManual.jpeg'; // or the name you want
    link.target = '_blank'; // This will open the PDF in a new tab to view
    document.body.appendChild(link);
    
    // Simulate a click on the link
    link.click();
    
    // Open the PDF in a new tab to view
    window.open(instructionManual);

    // Clean up the link element from the document
    document.body.removeChild(link);
};

const handleManageSubscription = () => {
  window.open("https://buy.stripe.com/4gweX6gclb2f4so4gg", "_blank") //to open new page
};
function timeout(delay: number) {
  return new Promise( res => setTimeout(res, delay) );
}




  const handleCopy = () => {
    // Construct the URL you want to copy
    const urlToCopy = `https://deafassistant.com/${encodeURIComponent(streamData.streamName.toLowerCase())}`;
  
    // Use the navigator.clipboard.writeText function to copy the URL
    navigator.clipboard.writeText(urlToCopy)
      .then(() => setCopied(true))
      .catch(err => console.error('Copying to clipboard failed: ', err));
  
    // Reset the 'copied' state after 2 seconds
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className='flex flex-col h-screen overflow-hidden'>
      <Header />
      {isSaving && (
  <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
    <div className='flex flex-col justify-center items-center h-[40%] w-[40%]'>
      <UpdatingAnimation/>
      
      
    </div>
  </div>
)}
{showSuccess && (
       <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
       <div className='flex flex-col justify-center items-center h-[40%] w-[40%]'>
        <SuccessAnimation />
        </div>
      </div>
    )}

      <div className="flex flex-1 overflow-auto bg-gray-50 ">
       
        <Sidebar  activeItem="overview"/>
      
      
      <main className="flex flex-col lg:flex-row flex-1">
        <div className="flex-1 p-8 pb-0">
          <div className="bg-white shadow-lg rounded-lg p-6 h-auto lg:h-[480px] mx-auto mb-6 lg:mb-0 lg:w-[720px]" >
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-2">
              <h1 className="text-xl lg:text-3xl font-semibold text-gray-800">Stream Details</h1>
                {!isEditing && (
                   <><button onClick={handleEditDetails} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 lg:mt-3">
                      <Edit/> Edit
                    </button></>
                )}
              </div>
              <div className='mb-4'>
                <p>Your information will be displayed here</p>
              </div>
              <div className="space-y-4">
                <div>
                  <label className="text-gray-600 mb-2">Stream Name</label>
                  <input
                    type="text"
                    value={streamData.streamName}
                    readOnly={!isEditing}
                    onChange={handleInputChange}
                    name="streamName"
                    className="w-full bg-gray-100 border focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-2xl text-base outline-none py-2 px-4 mt-3"
                    
                  />
                </div>
                <div>
                  <label className="text-gray-600 mb-2">Image URL</label>
                  <input
                    type="text"
                    value={streamData.imageUrl}
                    readOnly={!isEditing}
                    onChange={handleInputChange}
                    name="imageUrl"
                    className="w-full bg-gray-100 border focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-2xl text-base outline-none py-2 px-4 mt-3"
                  />
                </div>
                <div>
                  <label className="text-gray-600 mb-2">Stream Color</label>
                  {/* <input
                    type="color"
                    value={streamData.streamColor}
                    readOnly={!isEditing}
                    onChange={handleInputChange}
                    name="streamColor"
                    className="w-full bg-gray-100 border focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-2xl text-base outline-none py-2 px-4"
                  /> */}
                  <div className='relative mt-3'>
          <input
            placeholder='Stream Color Hexadecimal. Eg:#000000 for black'
            value={streamData.streamColor} // Bind color state to input
            onChange={handleInputChange}
            readOnly={!isEditing}
            // onFocus={handleFocus}
            // onBlur={() => setShowColor(false)}
            name="streamColor"
            className='w-full bg-gray-100 border focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-2xl text-base outline-none py-2 px-4 '
          />
          <div
            className='h-7 w-7 rounded-full absolute top-1/2 left-[93%] transform -translate-y-1/2'
            style={{ backgroundColor: streamData.streamColor }}
          ></div>
        </div>
                </div>
                <div className='mt-4'>
                {
                  !isEditing && (
                    <button
                    onClick={handleDownload}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 lg:mt-0 lg:mr-4"
                  >
                    <Download/> Download Manual
                  </button>
                  )
                }
                {
                  !isEditing && (
                    <button
                    onClick={() => window.open("https://youtu.be/qO8d_U870tY", '_blank')}  
                    className="bg-red-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 lg:mt-0 lg:mr-4"
                  >
                    <PlayArrow/> Demo
                  </button>
                  )
                }
                {
                  !isEditing && (                  
                  // <div className="flex flex-col lg:flex-row items-center justify-end ">
                    <button
                      onClick={handleManageSubscription}
                      className="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 lg:mt-0 lg:mr-4"
                      >
                      <Timer/> Manage Subscription
                    </button>
                  // </div>
                 
                )}

                {isEditing && (
                  
                  <div className="flex flex-col lg:flex-row items-center justify-end ">
                    <button
                      onClick={handleSaveDetails}
                      className={`font-bold py-2 px-4 rounded mr-4 transition-opacity duration-300 ${hasChanges ? 'bg-blue-500 hover:bg-blue-700 text-white' : 'bg-blue-400 text-white opacity-60 cursor-not-allowed'}`}
                      disabled={!hasChanges} // Disable the button if there are no changes
                    >
                      Save
                    </button>
                    <button onClick={handleCancelEdit} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                      Cancel
                    </button>
                    </div>
                 
                )}
                </div>
                
              </div>
            </div>
          </div>
          <div className="flex-1 p-8 flex flex-col items-center"> 
          <div className="bg-white shadow-lg rounded-lg p-6 mx-auto lg:h-[480px] lg:w-80">
          <div className="flex  items-center justify-center mb-2 mt-6">
            <h1 className="text-xl lg:text-3xl font-semibold text-gray-800 self-center">QR Code</h1>
          </div>
          <div className='mb-6 flex justify-center'>
            <p>Click on QR to copy the link</p>
          </div>
          <div className='pl-3 flex justify-center' onClick={handleCopy}>
          <QRCode value={`https://deafassistant.com/${encodeURIComponent(streamData.streamName.toLowerCase())}`} size={250} />
            {copied && <span className='pl-3'>Copied to clipboard!</span>}
          </div>
          <div className='mt-6 flex justify-center'>
            <a href={`https://deafassistant.com/${encodeURIComponent(streamData.streamName.toLowerCase())}`} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
            <Link/> {`deafassistant.com/${encodeURIComponent(streamData.streamName.toLowerCase())}`}
            </a>
          </div>
        </div>
      </div>
        </main>

      </div>
      
    </div>
  );
};

export default Overview;
